import * as _ from 'lodash';
import { ApiService } from '@shared/services/api.service';
import { BaseMapConfig } from '@shared/models/organization.config.model';
import { bbox, intersects, and, during } from 'ol/format/filter';
import { circular } from 'ol/geom/Polygon';
import { createBox } from 'ol/interaction/Draw';
import { defaults } from 'ol/control';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { EPSG_DEFINITIONS } from 'assets/map/epsg.definitions'
import { extend } from 'ol/extent.js';
import { Extent, getCenter } from 'ol/extent';
import { getDistance} from 'ol/sphere';
import { getTopLeft, getWidth } from 'ol/extent';
import { Injectable } from '@angular/core';
import { register } from 'ol/proj/proj4';
import { ResourceModel } from '@shared/models/resource.model';
import { transformExtent, transform } from 'ol/proj';
import Circle from 'ol/geom/Circle';
import Control from 'ol/control/Control';
import Draw from 'ol/interaction/Draw';
import Filter from 'ol/format/filter/Filter';
import FullScreen from 'ol/control/FullScreen';
import GML3 from 'ol/format/GML3';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import OlMap from 'ol/Map';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point.js';
import Polygon from 'ol/geom/Polygon';
import proj4 from 'proj4';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import WFS from 'ol/format/WFS';
import WKB from 'ol/format/WKB';
import WKT from 'ol/format/WKT';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import XYZ from 'ol/source/XYZ';
import TileSource from 'ol/source/Tile';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';


// Populated from National Information Exchange Model (NIEM) XML vocabulary information (http://www.datypic.com/sc/niem21/ss.html)
const DEFAULT_CLICK_RADIUS : number = 1;
const baseMapDefaultEpsg:string = 'EPSG:3857';
const baseMapDefaultExtent:Extent = [-20026376.39, -20048966.10, 20026376.39, 20048966.10];
const filterBboxLimit:number = 0.1 // 0.1 of kilometer
const WMS_VERSION:string = '1.3.0'
const WFS_VERSION:string = '2.0.0'
const MAP_TILES_URL:string = 'https://stamen-tiles-b.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png';
const OL_CONTROL_CLASS:string = 'ol-control';
const POPUP_ID:string = 'popup';
const WFS_STATUS_ID:string = 'service_status';
const TOGGLE_LEGEND_ID:string = 'toggleLegend';
const DEFAULT_ZOOM_LEVEL: Extent = [5,45,15,60];
const gmlGeometryComplexTypes:string[] = [
  "gml:AbstractCurveType", // geometryBasic (http://www.datypic.com/sc/niem21/s-geometryBasic0d1d.xsd.html)
  "gml:AbstractGeometricPrimitiveType",
  "gml:AbstractGeometryType",
  "gml:CurveArrayPropertyType",
  "gml:CurvePropertyType",
  "gml:DirectPositionListType",
  "gml:DirectPositionType",
  "gml:EnvelopeType",
  "gml:GeometricPrimitivePropertyType",
  "gml:GeometryArrayPropertyType",
  "gml:GeometryPropertyType",
  "gml:LineStringType",
  "gml:PointArrayPropertyType",
  "gml:PointPropertyType",
  "gml:PointType",
  "gml:VectorType",
  "gml:AbstractGeometricAggregateType", // geometryAggregates (http://www.datypic.com/sc/niem21/s-geometryAggregates.xsd.html)
  "gml:MultiCurvePropertyType",
  "gml:MultiCurveType",
  "gml:MultiGeometryPropertyType",
  "gml:MultiGeometryType",
  "gml:MultiPointPropertyType",
  "gml:MultiPointType",
  "gml:MultiSolidPropertyType",
  "gml:MultiSolidType",
  "gml:MultiSurfacePropertyType",
  "gml:MultiSurfaceType"
]
type Coordinate = [number, number];

@Injectable()
export class MapService {

  private map: OlMap;
  private wmsLayer: TileLayer<any>;
  private wmsServiceURL: string;
  private owsServiceParams: URLSearchParams;
  private layerExtent: Extent;
  private legendContent: Array<any> = [];
  private epsg: string;
  private transformedExtent: Extent;
  private timestampFilterEnabled: boolean = false;
  private timestampFilterStartDate: string;
  private timestampFilterEndDate: string;
  private baseMapEpsg: string;
  closestFeaturesList: Array<any>;
  vectorLayerDict: { [name: string]: string } = {};
  featurePopupDefaultPage: number = 1;
  pageSize = 1;
  count = 2;
  baseMapConfig: BaseMapConfig = this.dynamicInjector.getOrganizationConfig().datasetDetailsConfig.map.baseMapConfig 
  drawingEnabled = false;
  drawBorderStroke = new Style({
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 0.8)',
      width: 4
    }),
  })
  drawMainStroke = new Style({
    stroke: new Stroke({
      color: 'rgba(0, 153, 255, 0.8)',
      width: 3,
      lineDash: [4,8],
      lineDashOffset: 6
    }),
  })
  drawSource = new VectorSource({wrapX: false});
  drawLayer = new VectorLayer({
    source: this.drawSource, 
    style: [this.drawBorderStroke, this.drawMainStroke]
  });
  drawTool: Draw; // Draw object to enable/disable interaction with map.
  drawTypeSelected; // Store shape selected for drawing.
  drawFeatureGeometry; // Store WKB geometry of drawn bounding box.
  popupOverlay: Overlay;
  canZoomIn: boolean = false;
  readyToZoomIn: boolean = true;
  previousExtent: Extent | null = null;
  lastMapClickCoordinates: Coordinate;

  constructor(
    private apiService: ApiService,
    private dynamicInjector: DynamicInjectorService
    ) {
      this.showDetailsPopup = this.showDetailsPopup.bind(this)
  }
  
  private getBaseMapEpsg(): string{
    const projectionEpsg = `EPSG:${this.baseMapConfig.projection}`;
    // add new projection to proj4 definitions
    proj4.defs(projectionEpsg, this.getEpsgConfig(this.baseMapConfig.projection));
    // update proj4 definitions
    register(proj4)
    return projectionEpsg
  }

  public initMap(): void {
    this.baseMapEpsg = this.getBaseMapEpsg();
    this.popupOverlay = new Overlay(({
      element: document.getElementById(POPUP_ID),
      stopEvent: true,
      autoPan: true
    }));

    this.map = new OlMap({
      layers: [this.constructBaseMapLayer()],
      target: 'mapCanvas',
      controls: defaults({
        zoom: true,
        zoomOptions: {zoomInTipLabel: 'MAP_TAB.ZOOM_IN', zoomOutTipLabel: 'MAP_TAB.ZOOM_OUT'},
        attribution: true,
        attributionOptions: {tipLabel: 'MAP_TAB.ATTRIBUTION'},
        rotate: false,
      }),
      overlays: [this.popupOverlay],
      view: new View({
        projection: this.baseMapEpsg,
        maxZoom :20})
    });

    this.addMapControls()
    this.setEventListeners();
  }


  addMapControls = () => {
    // Add fullscreen icon
    this.map.getControls().extend([new FullScreen({})])
    // Control Legend
    const toggleLegend : HTMLElement = document.getElementById(TOGGLE_LEGEND_ID);
    const toggleLegendControl : Control = new Control({
      element: toggleLegend
    });
    this.map.addControl(toggleLegendControl);
    const legendBox : HTMLElement = document.getElementById('legendBox');
    legendBox.className = OL_CONTROL_CLASS;
    const legendBoxControl: Control = new Control({
        element: legendBox
    });
    this.map.addControl(legendBoxControl);
  }

  enableMapDrawing(shape){
    this.drawTypeSelected = shape
    if (this.drawingEnabled){
      this.resetMapDrawing()
    }
    else{
      this.drawingEnabled = true;
    }
    let drawType;
    let drawGeometryFunction;
    switch(shape) { 
      case 'Rectangle': { 
        drawType = 'Circle';
        drawGeometryFunction = createBox();
        break; 
      } 
      case 'Circle': { 
        drawType = 'Circle';
        break; 
      } 
      case 'Polygon': { 
        drawType = 'Polygon';
        break; 
      }
      default: { 
        break; 
      }
    } 
    this.drawTool = new Draw({
      source: this.drawSource,
      type: drawType,
      geometryFunction: drawGeometryFunction
    });
    this.onClickDrawActions()
    this.map.addInteraction(this.drawTool);
  }
  
  disableMapDrawing(){
    this.drawTypeSelected = null;
    this.drawingEnabled = false;
    this.resetMapDrawing()
  }

  resetMapDrawing(){
    /*
    Prepare new drawing action by cleaning draw hooks, cleaning the layer's source.
    */
    this.map.removeInteraction(this.drawTool);
    this.drawSource.clear()
  }

  private constructBaseMapLayer():TileLayer<any>{
    let baseMapLayer: TileLayer<any>;
    if (this.baseMapConfig.standard.toLowerCase() === 'xyz'){
      // Load XYZ tileset
      baseMapLayer = new TileLayer({
        source: new XYZ({
          url: this.baseMapConfig.endpoint[this.dynamicInjector.getEnvironment().environment],
          attributions: this.baseMapConfig.attributions,
          crossOrigin: null,
        }),
      });
    }
    else if (this.baseMapConfig.standard.toLowerCase() === 'wmts'){
      // load WMTS tile grid 
      const baseMapExtent = this.baseMapConfig.extent;
      const tileSizePixels = 256;
      const tileSizeMtrs = getWidth(baseMapExtent) / tileSizePixels;
      const matrixIds = [];
      const resolutions = [];
      for (let i = 0; i <= 15; i++) {
        matrixIds[i] = `${this.baseMapEpsg}:${i}`;
        resolutions[i] = tileSizeMtrs / Math.pow(2, i);
      }
      // console.debug(resolutions) // This is helpful to calculate resolutions for an unknown crs gridset configuration on geoserver side)
      const tileGrid = new WMTSTileGrid({
        origin: getTopLeft(baseMapExtent),
        resolutions: resolutions,
        matrixIds: matrixIds,
    })
      baseMapLayer = new TileLayer({
        opacity: 0.7,
        source: new WMTS({
          attributions: this.baseMapConfig.attributions,
          url: this.baseMapConfig.endpoint[this.dynamicInjector.getEnvironment().environment],
          layer: this.baseMapConfig.layer,
          format: this.baseMapConfig.format,
          matrixSet: this.baseMapEpsg,
          projection: this.baseMapEpsg,
          tileGrid: tileGrid,
          style: 'default',
          wrapX: false
        }),
      })
    }
    return baseMapLayer
  }

  private setEventListeners():void {
    const closer: HTMLElement = document.getElementById('popup-closer');
    closer.onclick = function () {
      this.popupOverlay.setPosition(undefined);
      closer.blur();
      return false;
    }.bind(this);
    this.map.on('singleclick', (evt: MapBrowserEvent<MouseEvent>) => {
      if (!this.drawingEnabled){
        this.onClickInMap(evt)
      }
    })
  };

  onZoomClick = (e, geometry) => {
    if (this.readyToZoomIn) {
      this.zoomToExtent(e, geometry.getExtent());
    } else {
      this.revertToPreviousExtent();
    }
  }

  zoomToExtent = (e, extent) => {
    if (!extent){
      return
    }
    this.previousExtent = this.map.getView().calculateExtent(this.map.getSize());
    this.map.getView().fit(extent, { padding: [50, 50, 50, 50] });
    this.readyToZoomIn = false;
  }

  revertToPreviousExtent = () => {
    if (this.previousExtent) {
      this.map.getView().fit(this.previousExtent, { padding: [50, 50, 50, 50] });
      this.readyToZoomIn = true;
    }
  }

  private onClickDrawActions():void{
    /* 
    When drawing is enabled, keep only one drawing(feature) at a time.
    Overwrite previous drawing(feature) on new drawing click.
    */
    this.drawTool.on('drawstart', () => {
      // If feature exists already, erase it.
      if (this.drawSource.getFeatures().length > 0){
        this.drawSource.clear()
      }
    });
    this.drawTool.on('drawend', function (e) {
      this.drawFeatureGeometry = null;
      if (this.drawTypeSelected != 'Circle'){
        const polygonFeature: Polygon = e.feature.getGeometry()
        this.drawFeatureGeometry = polygonFeature
      }
      else{
        const circleFeature: Circle = e.feature.getGeometry()
        this.drawFeatureGeometry = this.convertCircleFeatureToPolygonFeature(circleFeature)
      }
    }.bind(this))
  }

  private onClickInMap = async(e) => { 
    this.lastMapClickCoordinates = e.coordinate;
    this.readyToZoomIn = true; // reset state of zoom button for feature popup
    this.closestFeaturesList = []
    let layerPosition = 1
    const availableLayers = [];
    this.map.getLayers().forEach((layer) => {
      if (layer.getProperties().service === 'wms' && layer.getVisible() && layer.getProperties().className !== 'highlightLayer') {
        availableLayers.push(layer);
      }
    });  
    if (availableLayers.length === 0) {
      return;
    }
  
    this.hideDetailsPopup();
    this.vectorLayerDict = {};

    for (const layer of availableLayers) {
      const response = await this.getClosestFeature(layer, e);
      
      if (!response) {
        continue
      }
      const format = new GeoJSON();
      const feature = format.readFeature(response);
      const featureMetadata = response.properties
      const closestFeature = { 
        geometry: feature.getGeometry(),
        id: response.id,
        layer: layer,
        layerId: layer.getClassName(),
        layerName: layer.getProperties().layerName,
        metadata: Object.keys(featureMetadata).map(key => ({ key, value: featureMetadata[key] })),
        position: layerPosition++,
      };
      this.closestFeaturesList.push(closestFeature)
    }
    this.highlightFeature()
  }
  
  highlightFeature = () => {
    const featureCount = this.closestFeaturesList.length
    const highlightLayer = this.getHighlightLayer()
    if (featureCount == 0){
      highlightLayer.setOpacity(0)
      this.blurAllResourceLayers(false)
      return
    }
    
    //if only one layer with closestFeature, display it
    //if more than one layers with closestFeature, display position "1" feature
    this.highLightFeatureInPosition(1)
  }

  highLightFeatureInPosition = (position) => {
    this.canZoomIn = false;
    const closestFeature = this.closestFeaturesList.find(f => f.position === position);
    const featureGeometry = closestFeature.geometry;
    const featureExtent: Extent = featureGeometry ? featureGeometry.getExtent() : undefined; //fail-safe for ArcGIS geojson responses geometry null
    let featureCenter: Coordinate = this.lastMapClickCoordinates
    if (featureExtent){
      this.canZoomIn = true;
    } 
    if (featureGeometry && featureGeometry.getType() === "Point") {
      //Only if geometry type is point, multipoint or circle, we choose to use feature's center coordinates when placing the pop-up window.
      featureCenter = getCenter(featureExtent) as Coordinate
    }

    this.highlightClosestFeature(closestFeature.layer, closestFeature.id);
    this.showDetailsPopup(featureCenter)
  }

  /*
  Define opacity of resouce layers when a feature is found and highlighted
  */
  blurAllResourceLayers = (state:boolean) => {
    for ( const layer of this.getAllResourceLayers()){
      layer.setOpacity(state ? 0.5 : 1.0); //TODO investigate: opacity doesnt work properly with all MapServer / ArcGIS layers
    }
  }


  getAllResourceLayers(): Array<TileLayer<TileWMS>> {
    const allResourceLayers = this.map.getLayers().getArray().filter(layer => layer.getProperties().service === 'wms') as Array<TileLayer<TileWMS>>
    return allResourceLayers
  }

  /* 
  * Get highest available z-index
  */
  getHighestZIndex = () => {
    const result = Math.max(
      ...this.map.getLayers().getArray().map(layer => layer.getZIndex() || 0),
      0);
    return result
  }

  getClosestFeature = async (layer, e) => {
    const coordinate: Coordinate = e.coordinate;
    const resolution = this.map.getView().getResolution();
    const projection = this.map.getView().getProjection();
    //TODO this should be handled more appropriately: 
    // 1. either coming from CKAN metadata, or 
    // 2. store state in browser session to avoid unneeded calls and performance issues
    const featureInfoFormat = await this.getFeatureInfoFormat(layer) 
    const params = { 'INFO_FORMAT': featureInfoFormat };
    const url = layer.getSource().getFeatureInfoUrl(coordinate, resolution, projection, params);

    if (!url){
      console.error("Could not generate GetFeatureInfo URL.");
      return
    }
    try {
        // Fetch data from the URL
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.features && data.features.length > 0) {
          // only 1st closest feature returned. no logic for more than one features returned. 
          // TODO we currently don't support overlaping features in the same layer, unless customer complains.
          return data.features[0]
        }
    } catch (error) {
        console.error("Error fetching GetFeatureInfo:", error);
    }
  }
  //TODO move functions to different utilities ts
  getFeatureInfoFormat = async (layer) => {
    const sourceUrl = layer.getSource().getUrls()[0]
    const getCapabilitiesUrl = this.createGetCapabilitiesUrl(sourceUrl)
    const availableFormats = await this.fetchGetFeatureInfoFormats(getCapabilitiesUrl);

    switch (true) {
      case availableFormats.includes('application/json'):
        return 'application/json';
      case availableFormats.includes('application/geo+json'):
        return 'application/geo+json';
      default:
        return ''; // Return empty string if neither format is found
    }
  }
  
  //TODO move functions to different utilities ts
  createGetCapabilitiesUrl = (url) =>{
        // Parse the original URL
        const urlObj = new URL(url);
        // Create a new URL
        const baseUrl = urlObj.origin + urlObj.pathname; // Keep the base path
        const newUrl = new URL(baseUrl);
        // Add required parameters
        newUrl.searchParams.set("service", "WMS");
        newUrl.searchParams.set("version", WMS_VERSION);
        newUrl.searchParams.set("request", "GetCapabilities");
        return newUrl.toString();
  }

  //TODO move functions to different utilities ts
  fetchGetFeatureInfoFormats= async (getCapabilitiesUrl) => {
    try {
      // Fetch the GetCapabilities document
      const response = await fetch(getCapabilitiesUrl);
      if (!response.ok) {
          throw new Error(`Failed to fetch GetCapabilities: ${response.status} ${response.statusText}`);
      }

      const capabilitiesXml = await response.text();

      // Parse the XML response
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(capabilitiesXml, "application/xml");

      // Extract GetFeatureInfo formats
      const formats = Array.from(
          xmlDoc.querySelectorAll("Capability > Request > GetFeatureInfo > Format")
      ).map(formatNode => formatNode.textContent);

      // Return the extracted formats
      return formats;
  } catch (error) {
      console.error("Error processing GetCapabilities:", error);
      return [];
  }
  }

  /** 
  * Add closestFaeture inside highlight layer and make it visible, blur all resource layers.
  * @param {layer} TileLayer parent layer to retrieve it's source properties to update highlighlayer source
  * @param {highlightLayer} TileLayer system layer to push closest feature to highlight
  * @param {featureId} string feautre unique identifier to use as source cql_filter
  */
  private highlightClosestFeature(layer, featureId): void {
    const highlightLayer = this.getHighlightLayer()
    const layerSource: TileWMS = layer.getSource();
    // Build the CQL_FILTER dynamically
    const cqlFilter = `id IN ('${featureId}')`;
    // Clone the source with a new CQL_FILTER
    const highlightSource = new TileWMS({
      url: layerSource.getUrls()[0] as string,
      params: {
        ...layerSource.getParams(),
        CQL_FILTER: cqlFilter, // Add the new CQL_FILTER
      }
    });

    // Set the modified source to the highlightLayer
    highlightLayer.setSource(highlightSource);
    highlightLayer.setOpacity(1)
    // Reduce opacity of the original layer
    this.blurAllResourceLayers(true)
  }

  private hideDetailsPopup(): void {
    document.getElementById(POPUP_ID).hidden = true;
  }

  private showDetailsPopup(coordinates: Coordinate): void {
    this.featurePopupDefaultPage = 1
    document.getElementById(POPUP_ID).hidden = false;
    this.popupOverlay.setPosition(coordinates);
  }

  public getAllLayerLegendContent(): any {
    return this.legendContent;
  }

  public getLayerName(layer_id: string): any {
    return this.vectorLayerDict[layer_id];
  }
  public onPageChange($event) {
    this.closestFeaturesList =  this.closestFeaturesList.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }
  
  public getClosestFeaturesList(): Array<any> {
    return this.closestFeaturesList;
  }

  public featurePopupPageChange(position) {
    this.highLightFeatureInPosition(position)
    this.featurePopupDefaultPage = position;
  }

  public getLayersWithFeaturesCount(): number {
    let count: number = 1;
    if (this.closestFeaturesList){
      count = this.closestFeaturesList.length
    }
    return count;
  }

  private flashMessage(message: string, hide: boolean): void {
    document.getElementById(WFS_STATUS_ID).innerHTML = message
    document.getElementById(WFS_STATUS_ID).style.display = 'block';
    if (hide){setTimeout(function() {
      document.getElementById(WFS_STATUS_ID).style.display = 'none';
      }, 1000);
    };
  }; 

  private getEpsgConfig(srid): string {
    if (EPSG_DEFINITIONS.has(srid)){
      return EPSG_DEFINITIONS.get(srid)
    }
    else{
      console.warn(`Projection system ${srid} not found.`)
    }
  };

  getHighlightLayer = () => {
    const highlightLayer: TileLayer<TileWMS> = this.map.getLayers().getArray().find(layer => layer.getClassName?.() === "highlightLayer") as TileLayer<TileWMS>;
    if (!highlightLayer){
      console.error('highlight layer not found.')
    }
    return highlightLayer
  }

  renderSystemLayers = () => {
    const highlightLayer = new TileLayer({
      className: "highlightLayer",
      source: new TileWMS({
          url: "",
          params: {
              CQL_FILTER: "INCLUDE" // Default filter
          }
      }),
      properties: { type: "system"},
      opacity: 0, // not visible at start
      zIndex: this.getHighestZIndex() + 1, // Renders above the original layer
    });

    this.map.addLayer(highlightLayer); 
  }

  public async renderLayers(
    
    geoResources: Array<any> = []): Promise<void> {
  /*
  Display the WMS and WFS layers. Steps are:
  1. Strip incoming URLs from their parameters, after acquiring the typeName and layer name for WFS, WMS respectively
  2. Incoming layer_extent and layer_srid are transformed to match the openlayers projection system.
  3. Load Tile WMS layer and add to map.
  4. Load empty WFS layer and add to map. The features will be populated on click event.
  *. If WFS url is invalid, no WFS layer will be added to the map, no click event will trigger WFS logic.
  */
    let hasTimestampLayers: boolean = false;
    this.legendContent.length = 0;
    for (const resource of geoResources) {
      // construct layers
      this.addWMSLayer(resource);
      await this.addWFSLayer(resource);
      if (resource.timestamp_identifier){
        hasTimestampLayers = true
      }
    }
    // Control Draw
    if (this.dynamicInjector.getOrganizationConfig().datasetDetailsConfig.map.drawFilter){
      this.enableDrawControl()
    }
    // Control TimeSeries
    if (hasTimestampLayers){
      this.enableTimeSeriesControl()      
    }
    //Calculate extent to include all layers
    this.zoomToFullExtent()
  }

  private zoomToFullExtent(){
    let combinedExtent;
    this.map.getLayers().forEach(function(layer) {
      if (layer.getProperties().service === 'wms' && layer.getVisible()){
        if (!combinedExtent){
          combinedExtent = layer.getExtent()
        }
        extend(combinedExtent, layer.getExtent());
      }
    });
    this.map.getView().fit(combinedExtent,{
        padding: [50,50,50,50]
    });
  }

  public async getFeatureContent(url: string): Promise<any> {
    /*
    Convert observable request into firstValueFrom promise
    */
    let response = await (this.requestGetFeature(url))
    return response;

  }
  
  public requestGetFeature(url): Promise<JSON>  {
    /*
    Request getFeature from OWS service endpoint
    Properly handle exceptions
    */
    return this.apiService.getWFSFeatures(url).then(response => {
      return response;
    })
    
  }

  public async addWFSLayer(resource){
      if (resource.wfs_url){
        let featureAttributes: { [name: string]: string } = {};
        featureAttributes.layerSrid = `EPSG:${resource.layer_srid}`;
        const vectorSourceUrl: URL = new URL(resource.wfs_url);
        let search_params = vectorSourceUrl.searchParams;
        // acquire layer name from provided WFS endpoint param typeName
        const typeName: string = search_params.get('typeName')

        featureAttributes.serviceURL = this.harmonizeOwsURL(resource.wfs_url) //harmonize incoming WFS parameters
        if (!typeName){
          console.warn('WFS param "typeName" is missing. No features can be acquired.', false)
          return
        }
        else{
          if (!typeName.includes(":")){
            console.warn('WFS param "typeName" has invalid structure. No features can be acquired.', false)
            return
          };
          let featureJson: any
          if (typeName.includes(":")){
            featureAttributes.featurePrefix = typeName.split(":")[0]
            featureAttributes.featureType = typeName.split(":")[1]
            // read single feature from layer to store geometry attributes. Update params in wfs url
            search_params.set('outputFormat', 'application/json');
            search_params.set('count', '1');
            search_params.set('maxFeatures', '1');
            search_params.set('version', WFS_VERSION);
            vectorSourceUrl.search = search_params.toString();
            let featureContent = await this.getFeatureContent(vectorSourceUrl.toString())
            let featureList = featureContent?.features ?? [];
            if (featureList.length > 0){
              featureAttributes.geometryType = featureList[0].geometry.type;
              featureAttributes.geometryName = featureList[0].geometry_name;
              featureAttributes.layerName = resource.name;
              featureAttributes.timestamp_identifier = resource.timestamp_identifier;
              // Load empty WFS source but storing geometry attributes
              const wfsLayer = new VectorLayer({
                className: resource.id,
                properties: {service: 'wfs', attributes: featureAttributes},
                source: new VectorSource(),
                style: new Style({
                  stroke: new Stroke({
                    color: 'rgba(0, 0, 255, 1.0)',
                    width: 2,
                  }),
                }),
              });
              this.map.addLayer(wfsLayer);
            }
        };
        }
    }
  }
    
  public addWMSLayer(resource){
    const tileWMSSourceUrl:URL = new URL(resource.wms_url);
    const tileWMSSourceLayer = tileWMSSourceUrl.searchParams.get("layers")
    
    //strip incoming WMS url from params
    this.wmsServiceURL = this.stripURL(resource.wms_url) 
    this.owsServiceParams = resource.ows_url ? new URL(resource.ows_url).searchParams : undefined;
    // Set default extent If wms_extent is empty, zoom to Europe level
    this.layerExtent = DEFAULT_ZOOM_LEVEL; 
    
    if(resource.layer_extent) {
      if (typeof resource.layer_extent === 'string'){
        try { 
          this.layerExtent = (JSON.parse(resource.layer_extent));
        } catch(e) {
          console.warn(e, false)
        }
      } else {
        this.layerExtent = resource.layer_extent;
      }
    }
    else {
      console.warn('Layer extent not found.', true)
    }

    this.epsg = `EPSG:${resource.layer_srid}`;
    // add new projection to proj4 definitions
    proj4.defs(this.epsg, this.getEpsgConfig(resource.layer_srid));
    // update proj4 definitions
    register(proj4)

    try{
      // Apply default layer extent if provided, otherwise use the original layer's extent
      const defaultLayerExtent = this.dynamicInjector.getOrganizationConfig().datasetDetailsConfig.map.defaultLayerExtent
      this.transformedExtent = defaultLayerExtent ? defaultLayerExtent : transformExtent(this.layerExtent, this.epsg, this.baseMapEpsg);
    }   
    catch (Error){ 
      if ( Error.message === `Cannot read properties of null (reading 'getCode')` ) {
        console.warn(`Error trasforming bounding box. Global defaults applied.`, true)
      }
      else {
        console.warn(Error.message, false)
      }
    };
    if (!this.transformedExtent){
      this.transformedExtent = baseMapDefaultExtent;
    }
    let wmsParams: { [name: string]: any } = {
      'LAYERS': tileWMSSourceLayer,
      'TILED': true, 
      'VERSION': WMS_VERSION
    }
    let legendParams: { [name: string]: any } = {
      'LAYER': tileWMSSourceLayer,
      'REQUEST': 'GetLegendGraphic',
      'SERVICE': 'WMS',
      'LEGEND_OPTIONS': 'fontSize:11;'
    }
    // Retrieve additional params from ows_url, when exists, and include them in the service calls
    if (this.owsServiceParams){
      this.owsServiceParams.forEach((value, key) => {
        wmsParams[key] = value
        legendParams[key] = value
      });
    }

    let wmsSource: TileWMS = new TileWMS({
      url: this.wmsServiceURL,
      params: wmsParams,
      transition: 0,
    });
    this.wmsLayer = new TileLayer({
      // Transform geoserver 4326 (expected default value) extent to openlayers default projection 3857
      className: resource.id,
      extent: this.transformedExtent,
      source: wmsSource,
      opacity: 1,
      properties: {'timestamp_identifier': resource.timestamp_identifier, 'service': 'wms', 'layerName': resource.name}
    });
    this.map.addLayer(this.wmsLayer);
    
    this.legendContent.push({'position': this.legendContent.length, 'title': resource.name, 'layerName': resource.id, 'graphicURL': this.getLegendURL(wmsSource, legendParams), 'timestamp':resource.timestamp_identifier});
  }

  /**
   * Return the GetLegendGraphic URL including passed specific parameters
  **/
  getLegendURL(wmsSource: TileWMS, legendParams): string{
    return wmsSource.getLegendUrl(undefined, legendParams)
  }

  public updateLayerVisibility(layerName:string){
    this.hideDetailsPopup()
    this.map.getLayers().forEach(function (layer) {
      if (layerName === layer.getClassName()) {
        layer.setVisible(!layer.getVisible());
        // when layer is switched off, selected features must be reset.
        // layer.getSource().clear();   
      } 
      });
  }

 
  public refreshMap(startDateString: string, endDateString: string): void{
    this.timestampFilterStartDate = startDateString;
    this.timestampFilterEndDate = endDateString;
    if (startDateString && endDateString){
      this.timestampFilterEnabled = true;
    }
    else{
      this.timestampFilterEnabled = false;
    }
    this.map.getLayers().forEach(function (layer: any) {
      if (layer.getProperties().service === 'wms' && layer.getProperties().timestamp_identifier){
        const wmsSource = layer.getSource();
        const timestampColName = layer.getProperties().timestamp_identifier
        let wmsParams = wmsSource.getParams()
        if (this.timestampFilterEnabled){
          const cql_filter_date_query:string = `${timestampColName} >= ${startDateString} AND ${timestampColName} < ${endDateString}`
          wmsParams["cql_filter"] = cql_filter_date_query;
        }
        else{
          delete wmsParams["cql_filter"]
        }
        wmsSource.updateParams(wmsParams)
      }
    }.bind(this))
  }

  /**
  * Make use of the OL circular method to convert a circle feature to polygon feature.
  * Returns a default 32 vertices polygon out of a drawn circle.
  * @param {Circle} circleFeature circle on basemap coordinates
  * @param {number=} vertices number of circular polygon points to be generated
  * @returns {Polygon} circular polygon on basemap coordinates
  */
  convertCircleFeatureToPolygonFeature(circleFeature: Circle, vertices:number = 32): Polygon{
    // Prerequisite for the geometry calculations to be correct is that all coordinates are transformed from basemap projection to EPSG:4326 projection
    // When the new polygon is constructed, coordinates are then transformed back to original basemap projection.
    
    // Get center point of circle
    const centerPoint = circleFeature.getCenter()
    let transformedCenterPoint = transform(centerPoint, this.baseMapEpsg, 'EPSG:4326');
    
    // Calculate radius out of circle's extent height /2
    const topLeftPoint = [circleFeature.getExtent()[0], circleFeature.getExtent()[1]]
    const bottomLeftPoint = [circleFeature.getExtent()[2], circleFeature.getExtent()[1]]
    let transformedTopLeftPoint = transform(topLeftPoint, this.baseMapEpsg, 'EPSG:4326');
    let transformedBottomLeftPoint = transform(bottomLeftPoint, this.baseMapEpsg, 'EPSG:4326');
    let transformedRadius = getDistance(transformedTopLeftPoint, transformedBottomLeftPoint) / 2;
    
    // Construct new circular polygon with n vertices
    let polygonFeatureFromCircleFeature: Polygon = circular(transformedCenterPoint, transformedRadius, vertices)
    polygonFeatureFromCircleFeature.transform("EPSG:4326", this.baseMapEpsg)
    
    return polygonFeatureFromCircleFeature
  }

  public getDrawFeatureGeometry(resource: ResourceModel): string{
    // transform drawn shape from basemap projection to resource's original projection
    const originalProjectionGeometry = this.drawFeatureGeometry.clone().transform(this.baseMapEpsg,`EPSG:${resource.layer_srid}`)
    // const drawFeatureGeometryWKT = new WKT().writeGeometry(originalProjectionGeometry) as string;
    const drawFeatureGeometryWKB = new WKB().writeGeometry(originalProjectionGeometry) as string;

    return drawFeatureGeometryWKB
  }

  private enableDrawControl():void {
    const toggleDraw : HTMLElement = document.getElementById('toggleDraw');
    const toggleDrawControl : Control = new Control({
      element: toggleDraw
    });
    this.map.addControl(toggleDrawControl);
    const drawBox : HTMLElement = document.getElementById('drawBox');
    drawBox.className = OL_CONTROL_CLASS;
    const drawBoxControl : Control = new Control({
        element: drawBox
    });
    this.map.addControl(drawBoxControl);
    this.map.addLayer(this.drawLayer)
  }

  private enableTimeSeriesControl():void{
    const toggleTimestamp : HTMLElement = document.getElementById('toggleTimestamp');
      const toggleTimestampControl : Control = new Control({
        element: toggleTimestamp
      });
      this.map.addControl(toggleTimestampControl);
      const timestampBox : HTMLElement = document.getElementById('timestampBox');
      timestampBox.className = OL_CONTROL_CLASS;
      const timestampBoxControl : Control = new Control({
          element: timestampBox
      });
      this.map.addControl(timestampBoxControl);
  }

  public harmonizeOwsURL(url):string {
    if (!url){
      return '-'
    }
    let strippedURL: URL = new URL(this.stripURL(url))
    if (this.owsServiceParams){
      this.owsServiceParams.forEach((value, key) => {
        strippedURL.searchParams.set(key, value)
      });
    }
    return strippedURL.toString()
  }

  stripURL(url: string): string {
    const urlObj = new URL(url);
    urlObj.search = '';
    return urlObj.toString();
  }

}
